import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { bobaService } from '../services'
import {
  NotificationsBySalesDate,
  TenantNotificationBySalesDate,
} from '../services/api'
import { AppThunkConfig } from '../../store'
import { ApiRequest, AppError } from '../../AppTypes'
import { OutOfServiceError, AuthorizationError } from '../services/errors'
import {
  outOfServiceAppErr,
  authenticationErrorAppError,
  internalServerErrorAppError,
} from './common'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'

const service = bobaService()

type NotificationState = {
  tenantNotificationBySalesDates: TenantNotificationBySalesDate[]
  selectedSalesDateId?: string
  notificationsBySalesDate?: NotificationsBySalesDate
} & ApiRequest

const initialState: NotificationState = {
  apiStatus: 'Initial',
  tenantNotificationBySalesDates: [],
  selectedSalesDateId: undefined,
  notificationsBySalesDate: undefined,
  error: undefined,
}

const handleError = (err: Error): AppError => {
  if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  }
  if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const getTenantNotificationsBySalesDate = createAsyncThunk<
  TenantNotificationBySalesDate[],
  string,
  AppThunkConfig
>(
  'tenants/{tenant_id}/notifications-by-sales-date',
  async (args, { getState, rejectWithValue }) => {
    try {
      return await service.getTenantNotificationsBySalesDate(args, getState())
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const getNotificationsBySalesDate = createAsyncThunk<
  NotificationsBySalesDate,
  { tenantId: string; salesDateId: string },
  AppThunkConfig
>(
  'tenants/{tenant_id}/salesdates/{sales_date_id}/notifications',
  async (args, { getState, rejectWithValue }) => {
    try {
      return await service.getNotificationsBySalesDate(
        args.tenantId,
        args.salesDateId,
        getState()
      )
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotifications: (): NotificationState => {
      return {
        ...initialState,
      }
    },
    clearAPIStatus: (state): NotificationState => {
      return {
        ...state,
        apiStatus: 'Initial',
        error: undefined,
      }
    },
    setSelectedSalesDateId: (state, action): NotificationState => {
      const selectedSalesDateId = action.payload
      return {
        ...state,
        selectedSalesDateId,
      }
    },
    clearSelectedSalesDateId: (state): NotificationState => {
      return {
        ...state,
        selectedSalesDateId: undefined,
      }
    },
    clearNotificationsBySalesDate: (state): NotificationState => {
      return {
        ...state,
        notificationsBySalesDate: undefined,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTenantNotificationsBySalesDate.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(
      getTenantNotificationsBySalesDate.fulfilled,
      (state, action) => {
        disableLeaveConfirmation()
        const tenantNotificationBySalesDates = action.payload
        return {
          ...state,
          apiStatus: 'Success',
          tenantNotificationBySalesDates,
        }
      }
    )
    builder.addCase(
      getTenantNotificationsBySalesDate.rejected,
      (state, action) => {
        disableLeaveConfirmation()
        return {
          ...state,
          apiStatus: 'Failure',
          error: action.payload,
        }
      }
    )
    builder.addCase(getNotificationsBySalesDate.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(getNotificationsBySalesDate.fulfilled, (state, action) => {
      disableLeaveConfirmation()
      const notificationsBySalesDate = action.payload
      return {
        ...state,
        apiStatus: 'Success',
        notificationsBySalesDate,
      }
    })
    builder.addCase(getNotificationsBySalesDate.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  clearNotifications,
  clearAPIStatus,
  setSelectedSalesDateId,
  clearSelectedSalesDateId,
  clearNotificationsBySalesDate,
} = notificationsSlice.actions

export default notificationsSlice
