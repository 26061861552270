import React from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import Typography from '../../atoms/Typography'
import styles from './style.module.scss'
import Icon from '../../atoms/Icon'

type SalesDateNotificationProps = {
  salesDate: string
  lastCreatedAt: string
  onClick: (salesDate: string) => void
} & React.StyledProps

const SalesDateNotification: React.FC<SalesDateNotificationProps> = ({
  className,
  salesDate,
  lastCreatedAt,
  onClick,
}: SalesDateNotificationProps) => {
  dayjs.locale('ja')
  return (
    <div
      className={clsx(className, styles.root)}
      onClick={(): void => onClick(salesDate)}
    >
      <div className={styles.container}>
        <span className={styles.date}>
          {/* FIXME: 共通化して、他の同じ処理も置き換える */}
          {dayjs(salesDate).format('YYYY.MM.DD(ddd)')}
        </span>
        <div className={styles.reportTime}>
          <span>最終受信日時：</span>
          <span>{dayjs(lastCreatedAt).format('YYYY.MM.DD(ddd) HH:mm')}</span>
        </div>
      </div>
      <div className={styles.confirm}>
        <Typography className={styles.confirmText}>確認する</Typography>
        <Icon icon="next" />
      </div>
    </div>
  )
}

export default SalesDateNotification
