import React from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'
import styles from './style.module.scss'
import Icon from '../../atoms/Icon'

type Props = {
  date: string
  reportedAt?: string
  status: Status
  onClick: () => void
} & React.StyledProps

type Status =
  | 'waiting'
  | 'reported'
  | 'imageResent'
  | 'finalizedReportSubmitted'
  | 'finalizedReportSynced'

const SalesDateRecord: React.FC<Props> = ({
  date,
  reportedAt,
  status,
  onClick,
  className,
}: Props) => {
  dayjs.locale('ja')

  const convertStatus = (reportStatus: Status): string => {
    switch (reportStatus) {
      case 'waiting':
        return '未報告'
      case 'reported':
        return '報告済'
      case 'finalizedReportSubmitted':
      case 'finalizedReportSynced':
        return '確定'
      case 'imageResent':
      default:
        return '再報告済み'
    }
  }

  const getMessageFromStatus = (reportStatus: Status): string => {
    switch (reportStatus) {
      case 'waiting':
        return 'この日付で報告'
      case 'finalizedReportSubmitted':
      case 'finalizedReportSynced':
        return '再送信不可'
      default:
        return '画像のみ再送信'
    }
  }

  const clickable = ![
    'finalizedReportSubmitted',
    'finalizedReportSynced',
  ].includes(status)

  return (
    <div
      className={clsx(className, styles.root, styles[status])}
      onClick={onClick}
    >
      <div className={styles.dateWrapper}>
        <span className={styles.date}>
          {dayjs(date).format('YYYY.MM.DD(ddd)')}
        </span>
        {status !== 'waiting' && reportedAt && (
          <div className={styles.reportTime}>
            <span>報告日時:</span>
            <span>{dayjs(reportedAt).format('YYYY.MM.DD(ddd) HH:mm')}</span>
          </div>
        )}
      </div>
      <div className={styles.statusWrapper}>
        <div className={clsx(styles.status, styles[status])}>
          <Icon icon="statusLamp" className={styles.statusIcon} />
          <span>{convertStatus(status)}</span>
        </div>
        <div className={styles.clickable}>
          <div className={clsx(styles.button, styles[status])}>
            <span>{getMessageFromStatus(status)}</span>
            {clickable && <Icon icon="next" className={styles.icon} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesDateRecord
