import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Div100vh from '../../components/atoms/Div100vh'
import useAppHistory from '../../utils/useAppHistory'
import { RootState } from '../../store'
import AppHeader from '../../components/organisms/AppHeader'
import {
  reportCommandPath,
  genTenantNotificationBySalesDatesPath,
  topWithClearDataPath,
} from '../../routes/paths'
import useTenant from '../hooks/useTenant'
import Typography from '../../components/atoms/Typography'
import {
  description,
  noTenantNotificationBySalesDateDescription,
  title,
} from './messages'
import style from './styles.module.scss'
import {
  clearAPIStatus,
  getTenantNotificationsBySalesDate,
  setSelectedSalesDateId,
} from '../../features/network/notificationsSlice'
import ErrorModal from '../../components/organisms/ErrorModal'
import SalesDateNotification from '../../components/molecules/SalesDateNotification'
import { AppError } from '../../AppTypes'
import LoadingModal from '../../components/organisms/LoadingModal'

function TenantNotificationBySalesDates(): ReactElement {
  const history = useAppHistory()
  const dispatch = useDispatch()
  const tenant = useTenant()

  const { tenantNotificationBySalesDates, apiStatus, error } = useSelector(
    (state: RootState) => state.notifications
  )

  useEffect(() => {
    dispatch(getTenantNotificationsBySalesDate(tenant.id))
  }, [])

  const handleButtonClick = (salesDate: string): void => {
    const selectedSalesDateId = tenantNotificationBySalesDates.find(
      (tenantNotificationBySalesDate) =>
        tenantNotificationBySalesDate.salesDate === salesDate
    )?.salesDateId
    if (!selectedSalesDateId) return

    dispatch(setSelectedSalesDateId(selectedSalesDateId))
    history.push(genTenantNotificationBySalesDatesPath(salesDate))
  }

  const handleErrorModalButtonClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
    } else {
      dispatch(clearAPIStatus())
      history.push(reportCommandPath)
    }
  }

  return (
    <Div100vh className={style.root} disabledModeWrapper>
      <AppHeader
        tenantName={tenant.tenantName}
        storeName={tenant.storeName}
        onClickBackReportHistory={(): void => history.push(reportCommandPath)}
      />
      <div className={style.titleWrapper}>
        <Typography variant="largeTitle" align="left" bold>
          {title}
        </Typography>
        <Typography
          className={style.description}
          variant="description"
          align="left"
        >
          {!tenantNotificationBySalesDates
            ? noTenantNotificationBySalesDateDescription
            : description}
        </Typography>
      </div>
      {tenantNotificationBySalesDates &&
        tenantNotificationBySalesDates.map((tenantNotificationBySalesDate) => (
          <SalesDateNotification
            className={style.tenantNotificationBySalesDate}
            key={tenantNotificationBySalesDate.salesDateId}
            salesDate={tenantNotificationBySalesDate.salesDate}
            lastCreatedAt={tenantNotificationBySalesDate.lastCreatedAt}
            onClick={handleButtonClick}
          />
        ))}
      <LoadingModal isOpen={apiStatus === 'Progress'} />
      {error && (
        <ErrorModal
          isOpen={Boolean(error)}
          title={error.title}
          description={error.description}
          buttonTitle="OK"
          onClick={(): void => handleErrorModalButtonClick(error)}
        />
      )}
    </Div100vh>
  )
}

export default TenantNotificationBySalesDates
