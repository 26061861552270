import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import useAppHistory from '../../utils/useAppHistory'
import { RootState } from '../../store'
import Div100vh from '../../components/atoms/Div100vh'
import AppHeader from '../../components/organisms/AppHeader'
import useTenant from '../hooks/useTenant'
import {
  reportCommandPath,
  topWithClearDataPath,
  tenantNotificationBySalesDatesPath,
} from '../../routes/paths'
import style from './styles.module.scss'
import Typography from '../../components/atoms/Typography'
import { title } from './message'
import Button from '../../components/atoms/Button'
import {
  clearAPIStatus,
  clearNotificationsBySalesDate,
  clearSelectedSalesDateId,
  getNotificationsBySalesDate,
} from '../../features/network/notificationsSlice'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import LoadingModal from '../../components/organisms/LoadingModal'

function NotificationsBySalesDate(): ReactElement {
  dayjs.locale('ja')

  const history = useAppHistory()
  const dispatch = useDispatch()
  const tenant = useTenant()

  const {
    notificationsBySalesDate,
    selectedSalesDateId,
    apiStatus,
    error,
  } = useSelector((state: RootState) => state.notifications)

  useEffect(() => {
    if (!selectedSalesDateId) return

    dispatch(
      getNotificationsBySalesDate({
        tenantId: tenant.id,
        salesDateId: selectedSalesDateId,
      })
    )
  }, [])

  const handleClickBackReportHistory = (): void => {
    dispatch(clearNotificationsBySalesDate())
    dispatch(clearSelectedSalesDateId())
    history.push(reportCommandPath)
  }

  const handleClickClose = (): void => {
    dispatch(clearSelectedSalesDateId())
    history.push(tenantNotificationBySalesDatesPath)
  }

  const handleErrorModalButtonClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
    } else {
      dispatch(clearAPIStatus())
      history.push(reportCommandPath)
    }
  }

  return (
    <Div100vh className={style.root} disabledModeWrapper>
      <AppHeader
        tenantName={tenant.tenantName}
        storeName={tenant.storeName}
        onClickBackReportHistory={handleClickBackReportHistory}
      />
      <div className={style.container}>
        <div>
          <Typography variant="largeTitle" align="left" bold>
            {title}
          </Typography>
          <Typography
            className={style.salesDate}
            variant="description"
            align="left"
          >
            売上日：
            {dayjs(notificationsBySalesDate?.salesDate).format(
              'YYYY.MM.DD(ddd)'
            )}
          </Typography>
          <Typography
            className={style.lastCreatedAt}
            variant="description"
            align="left"
          >
            最終受信日時：
            {dayjs(notificationsBySalesDate?.lastCreatedAt).format(
              'YYYY.MM.DD(ddd) HH:mm'
            )}
          </Typography>
        </div>
        {notificationsBySalesDate &&
          notificationsBySalesDate?.notifications?.map((notification) => (
            <div className={style.notification} key={notification.id}>
              <Typography
                className={style.createdAt}
                variant="description"
                align="left"
              >
                受信日時：
                {dayjs(notification.createdAt).format('YYYY.MM.DD(ddd) HH:mm')}
              </Typography>
              <Typography className={style.content} variant="description">
                {notification.content}
              </Typography>
            </div>
          ))}
        <div className={style.closeButtonWrapper}>
          <Button color="white" onClick={(): void => handleClickClose()}>
            確認して閉じる
          </Button>
        </div>
      </div>
      <LoadingModal isOpen={apiStatus === 'Progress'} />
      {error && (
        <ErrorModal
          isOpen={Boolean(error)}
          title={error.title}
          description={error.description}
          buttonTitle="OK"
          onClick={(): void => handleErrorModalButtonClick(error)}
        />
      )}
    </Div100vh>
  )
}

export default NotificationsBySalesDate
